@media screen and (max-width: 960px) {
    .MuiTabs-vertical {
        flex-direction: row !important;
        .MuiTabScrollButton-vertical {
            width: 40px !important;
        }
        .MuiTabs-flexContainerVertical {
            flex-direction: row !important;
        }
        .MuiTabs-indicator {
            display: none !important;
        }
        .MuiTabs-scrollButtons {
            display: none !important;
        }
    }
    .tabpanels {
        .MuiBox-root {
            padding-top: 0px !important;
        }
    }
}

@media screen and (min-width: 960px) {
    .MuiTabScrollButton-root {
        display: none !important;
    }
    .tabsContainer {
        padding-top: 24px;
        padding-bottom :24px;
    }
}

.gasRoot {
    .gasHero {
        background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/gas-services-header.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logosmaller {
        width: 250px;
    }

    .logosmaller1 {
        width: 250px;
    }

    .medicalRack {
        padding-top: 10px;
        width: 100%;
        height: 350px;
        object-fit: cover;
    }

    .tabTitle {
        font-weight: bold;
        color: #F4DD09;
    }

    .bulkVideo {
        padding-top: 10px;
        width: 100%;
    }
    
    .center {
        text-align: center;
    }

    .logosmaller2 {
        width: 300px;
    }

    .subtitle2 {
        text-align: center;
        color: white;
        font-family: clarendon-text-pro, serif;
        font-weight: 400;
        font-style: normal;
        text-shadow: 2px 2px black;
    }

    .gasServiceContainer {
        padding: 10px 0;

        .paperTabContainer {
            background-color: #1d1d1d;
            color: white;
            display: flex;
            flex-grow: 1;

            .gasLink {
                text-decoration: none;
                color: #3f51b5;
            }

            .MuiTabs-indicator {
                background-color: #F4DD09;
            }
        }
    }

    .gasServiceContainer2 {
        padding-bottom: 50px;
    }

    .subtitle4 {
        text-align: center;
        padding-bottom: 50px;
    }

    .spacingTop {
        padding-top: 16px;
    }

}

.tabsContainer {
    .MuiSvgIcon-root {
        fill: #F4DD09;
    }
    .MuiTabs-flexContainer {
        .Mui-selected {
            color: #F4DD09;
        }
    }

}