.repairRoot {
    .repairHero {
        background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/service-shop-header.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h5 {
        font-weight: bold;
    }

    .wisContent {
        text-align: justify;
    }

    .serviceLogo {
        height: 150px;
    }

    .whitetext {
        color: white !important;
        font-weight: normal
    }

    .gray {
        color: gray;
    }
    
    .subtitle2 {
        text-align: center;
        color: white;
        font-family: clarendon-text-pro, serif;
        font-weight: 400;
        font-style: normal;
        text-shadow: 2px 2px black;
    }
    .repairContainer {
        margin: 10px 0;
        .serviceMFG {
            margin-top: 10px;
            text-align: center !important;
        }
        .repairPaper {
            background-color: #1d1d1d;
            color: white;
            height: 100%;
            .repairSubtitle {
                color: #F4DD09;
            }
        }

        .insidePadding {
            padding: 25px;
        }
        .repairPaper2 {
            padding: 25px;
            background-color: #F4DD09;
        }
    }
}