@media screen and (max-width: 600px) {
    .mapWrapper {
        height: 500px;
    }

    .test3 {
        padding-bottom: 5px;
    }
}

.notworking {
    margin: 0 !important; 
    padding-bottom: 20px;
}

.creditapp {
    height: 100%;
    background-color: #1d1d1d !important;
    padding: 10px;

    .creditImgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        
        .creditImg {
            width: 250px;
        } 
    }
}

.contactRoot {
    margin-bottom: 10px;

    .captcha {
        display: flex;
        justify-content: center;
    }

    .mapWrapper {
        height: 500px;
    }

    .cardHeading {
        text-align: center;
        background-color: #1d1d1d;
        color: #f4dd09;

        border-radius: 0px !important;


        .cardContent1 {
            padding-bottom: 0px !important;
        }

        .expandIcon {
            fill: #f4dd09;
            font-size: 2em;
        }

        .employmentImg {
            width: 25%;
            padding-bottom: 15px;
        }

        .spacing {
            padding-bottom: 5px;
        }

        .hrCard {
            margin-top: 15px;
            border-radius: 0px !important;
            text-align: left;
            background-color: #f4dd09;
        }

        .employmentCardHolder {
            text-align: left;
            .employmentCard {
                text-align: left;
                background-color: #f4dd09;
                margin-bottom: 5px;
    
                border-radius: 0px !important;
            }
            .greyys {
                color: gray;
            }
        }

    }

    .rotate {
        transform: rotate(180deg);
        transition: all .25s linear;
    }

    .xd {
        font-weight: bold;
    }

    .checkmark {
        color: #f4dd09;
    }
}

.terms {
    margin-top: 75px;
    text-align: center;
    .termsLink {
        color: white;
    }
    .termsLink:hover {
        color: #F4DD09;

    }
}

.contactFormPaper {
    padding: 10px;
    background-color: #1d1d1d !important;
    color: white;
    height: 100%;

    .contactForm > * {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .contactFormSubmit {
        width: 100%;
        background-color: #f4dd09;
    }
    .center {
        text-align: center;
    }
    .MuiSvgIcon-root {
        text-align: center;
        font-size: 7em;
    }

    .MuiFormLabel-root {
        color: rgba(255, 255, 255, 0.35);
    }

    .PrivateNotchedOutline-root-327, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiFormLabel-root.Mui-focused, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiFormLabel-root, .MuiButton-outlined {
        border-color: rgba(255, 255, 255, 0.35);
    }

    .MuiFormLabel-root.Mui-focused {
        color: #F4DD09;
    }

    .MuiInputBase-input {
        color: white;
    }
}

.locations {
    padding: 15px 0;
}

.contactHero {
    background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/contact-header.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    .subtitle {
        text-align: center;
        color: white;
        font-family: clarendon-text-pro, serif;
        font-weight: 400;
        font-style: normal;
        text-shadow: 2px 2px black;
    }    
}

.contactContent {
    margin-top: 10px;
}

.subtitle2 {
    text-align: center;
}

.subtitle20 {
    text-align: center;
    padding: 25px;
    color: #F4DD09 !important;
}

.subtitle5 {
    text-align: center;
    padding-bottom: 25px;
    color: white;
}

.map {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

