@media screen and (max-width: 600px) {
    .separator {
        margin: 0 50px 0 0 !important; 
    }
    .timeline-items::before {
        content: none !important;
    }
    .timeline-items:nth-of-type(even) .content::before {
        left: auto !important;
        border-color: transparent !important;
        border-right-color: #1d1d1d !important;
    }
}

.timeline-root {
    .timeline-items {       
        .separator {
            margin: 0 50px;

            .icon-wrapper {
                background-color: #1d1d1d;
            }
        }

        .content {
            position: relative;
            background-color: #1d1d1d;
            color: white;
            margin: 10px 0;

            .title {
                color: #F4DD09;
            }
        }
    }

    .content::before {
        content: '';
        position: absolute;
        left: auto;
        right: 100%;
        border: 7px solid transparent;
        border-right: 7px solid #1d1d1d;
        top: calc(50% - 7px);
    }
    
    .timeline-items:nth-of-type(even) .content::before {
        left: 100%;
        border-color: transparent;
        border-left-color: #1d1d1d;
    }
}