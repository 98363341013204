@media screen and (max-width: 960px) {
    .locationcareerHero {
        background-size: cover !important;
    }
    .locationSelector {
        width: 200px !important;
    }
    .llGoogle {
        width: 100%;
        margin-top: 10px;
    }
}

.locationcareerRoot {
    .locationcareerHero {
        background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/locations-header.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .heroSub {
            text-align: center;
            color: white;
            font-family: clarendon-text-pro, serif;
            font-weight: 400;
            font-style: normal;
            text-shadow: 2px 2px black;
        }
    }

    .needsBolding {
        font-weight: bold;
        color: #F4DD09 !important;
    }
    .locationslistRoot {
        margin: 10px 0;
        
        .locationPadding {
            padding: 10px;
        }
    
        .locationPaper {
            border-radius: 0;
            background-color: #1d1d1d;
            color: #fff;
        }
    
        .llImg {
            width: 100%;    
            height: 250px;
        }
    
        .llLocation {
            margin-bottom: 10px;
            color: #F4DD09;
        }
    
        .llSub {
            display: flex;
            align-items: center;
            justify-content: left;
        }
        
        .spacing {
            margin-bottom: 10px;
        }
        
        .dividerSpacing {
            margin: 10px 0;
            background-color: #848484 !important;
        }
    
        .bolding {
            font-weight: bold;
            color: #F4DD09;
        }
    
        .llIcon {
            padding-right: 10px;
            color: #F4DD09;
        }
        
        .llGoogle {
            border: none;  
            height: 250px;  
        }
    }
    .locationSelectorWrapper {
        margin-top: 10px;
        text-align: center;
        

        .locationSelectorPaper {
            padding: 10px;
            border-radius: 0;
            background-color: #1d1d1d;
            
            .subtitle {
                color: white;
            }

            .formLocation {
                margin-top: 10px;
            }

            .locationSelector {
                color: #fff;
                height: 56px;
                width: 500px;
                margin-right: 10px;
            }
            
            .inputLocation {
                color: #F4DD09 !important;
            }

            .locationSelect {
                color: #F4DD09 !important;
                background-color: #1d1d1d !important;
            }

            .background {
                background-color: #1d1d1d !important;
            }

            .MuiOutlinedInput-notchedOutline {
                border-color: #fff;
            }

            .MuiSelect-icon {
                color: #fff;
            }

            .locationButton {
                border-color: #fff;
                color: #F4DD09 !important;
                height: 56px;
            }
        }
    }
}