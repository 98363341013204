.footerRoot {
    padding: 10px 0px;
    background-color: #1d1d1d;
    color: #F4DD09;

    .footerLink {
        text-decoration: none;
        color: inherit;
    }

    .footerLink svg {
        transition: .5s ease-in-out;
    }

    .footerLink:hover svg {
        transform: scale(1.3);
    }

    h6 {
        font-weight: bold;
    }
    
    .MuiListItem-gutters {
        padding-left: 0;
        padding-right: 0;
    }

    .MuiAvatar-colorDefault {
        color: #F4DD09;
        background-color: #1d1d1d;
        border-color: rgba(255, 255, 255, 0.35);
        border-width: 1px;
        border-style: solid;
    }

    .MuiTypography-colorTextSecondary {
        color: white;
    }

    .subheader {
        color: white;
    }

    .email-submit {
        margin-top: 16px;
        margin-left: 8px;
        margin-bottom: 8px;
        height: 56px;
    }

    .PrivateNotchedOutline-root-327, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiFormLabel-root.Mui-focused, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiFormLabel-root, .MuiButton-outlined {
        border-color: rgba(255, 255, 255, 0.35);
    }

    .MuiFormLabel-root {
        color: rgba(255, 255, 255, 0.35);
    }


    .MuiFormLabel-root.Mui-focused, .email-submit {
        color: #F4DD09;
    }

    .email-enter, .MuiInputBase-root {
        color: white;
    }

    .fabIcon {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.35);
        border-width: 1px;
        border-style: solid;
        color: #F4DD09;
        margin: 5px 10px 5px 0px;
    }
    .tiktokIcon {
        color: #F4DD09;
    }

    .fabIcon:hover {
        background-color: transparent;
    }

    .dividerSpacing {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .footerAddressWrapper {
        padding-top: 0px;
    }

    .copyright {
        margin-bottom: 5px;
    }
}