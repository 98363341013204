@media screen and (max-width: 960px) {
    .header {
        background-size: auto 100% !important;
    }

    .guideWrapper {
        margin-top: 20px !important;
    }

    .newsletter-email-text-wrapper {
        margin-right: 15px !important;

        .newsletter-email-text {
            width: 225px !important;
        }
    }
}

.homeRoot {
    margin-bottom: 10px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;    
    background-image: url('../../imgs/BWS-75-Anniversary-Header.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 400px;

    img {
        width: 500px;
        margin-top: 25px;
        padding-bottom: 25px;
    }
}

    
.sub-heading {
    text-align: center;
    margin-bottom: 25px;
}    

.home-content {
    margin-top: 10px;
    .specials {
        margin-bottom: 25px;
        .flyerContent {
            background-color: #1d1d1d;
            color: white;
            padding: 10px;
            height: 100%;
            .flyerImgWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 25px;
                padding-top: 25px;
                
                .flyerImg {
                    width: 150px;
                }
            }
        }
    
        .guideContent {
            background-color: #1d1d1d;
            color: white;
            padding: 10px;
            height: 100%;
            .guideImgWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 25px;
                padding-top: 25px;
                
                .guideImg {
                    width: 275px;
                    height: 187.983px;
                    }
            }
        }

        h4 {
            text-align: center;
            color: #F4DD09;
            font-weight: bold;
        }
    }


    .servicesTitle {
        margin-bottom: 10px;
    }

    .servicesContent {
        margin-bottom: 10px;
        background-color: #1d1d1d;
        padding: 10px;
        text-align: center;
        color: #F4DD09;

        .servicesText {
            padding: 25px;
            text-align: left;

            .homeButton {
                margin-top: 25px;
            }
        }

        h4 {
            font-weight: bold;
        }

        h5 {
            color: white;
        }

        button {
            margin-top: 10px;
        }

        img {
            width: 300px;
            height: 300px;
        }
    }

    .dryiceWrapper {
        margin-bottom: 0px !important;
    }

    .noLinks {
        text-decoration: none;
        color: inherit;
    }

    .productsHeader {
        text-align: center;
        padding: 0px;

        .productCategories {
            padding: 25px 0px;
            font-weight: bold;
        }

        h5 {
            font-weight: bold;
        }

        .MuiAccordionSummary-root {
            background-color: #F4DD09;

            svg {
                transition: all .25s linear;
            }
            .rotate {
                transform: rotate(180deg);
            }
        }

        .MuiAccordionDetails-root {
            background-color: #F4DD09;
        }

        .MuiAccordionSummary-content {
            display: block;
            margin-bottom: 0;
        }

        img {
            margin-top: 25px;
            margin-bottom: 10px;
            width: 125px;
            height: 125px;
        }

    }
}
