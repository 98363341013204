@media screen and (max-width: 600px) {
    .rentalRoot {
        .sameHeight {
            height: auto !important;
        }
    }
}

.rentalRoot {
    .rentalHero {
        background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/rental-equipment-header.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .subtitle2 {
        text-align: center;
        color: white;
        font-family: clarendon-text-pro, serif;
        font-weight: 400;
        font-style: normal;
        text-shadow: 2px 2px black;
    }

    h5 {
        font-weight: bold;
    }

    .imagefix {
        width: 100%;
        height: 200px;
    }

    .rentalContainer {
        margin: 10px 0px;
        
        .rentalPaper {
            background-color: #1d1d1d;
            color: white;
            padding: 25px;
        }
        .sameHeight {
            height: 200px;
            text-align: justify;
        }

        .subtitle3 {
            text-align: center;
            color: #F4DD09;
            margin-bottom: 10px;
        }
        
        .rentalSubtitle {
            color: #F4DD09;
        }
    }
}