.careerOpportunitiesRoot {
    .careerOpportunitiesHero {
        background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/career-header.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .heroSub {
            text-align: center;
            color: white;
            font-family: clarendon-text-pro, serif;
            font-weight: 400;
            font-style: normal;
            text-shadow: 2px 2px black;
        }
    }

    .careerOpportunitiesContent {
        margin: 10px 0;
        
        .careerOpportunitiesPaper {
            background-color: #1d1d1d;
            padding: 10px;

            .careerOpportunitiesSub {
                font-weight: bold;
                text-align: center;
                color: #f4dd09;
                margin-bottom: 25px;
            }
            .careerOpportunitiesInfoCard {
                background-color: #f4dd09;
                .careerOpportunitiesCardSub {
                    font-weight: bold;
                }
            }

            .careerOpportunitiesImgWrapper {
                text-align: center;
                justify-content: center;

                .careerOpportunitiesImg {
                    width: 25%;
                    padding-bottom: 15px;
                }
                .careerOpportunitiesImgSub {
                    color: #f4dd09;
                    font-weight: bold;
                }
            }
            .careerOpportunitiesCard {
                background-color: #f4dd09;
                height: 100%;

                .careerOpportunitiesCardSub {
                    font-weight: bold;
                }
            }
        }
        .careerOpportunitiesLastUpdated {
            color: gray;
            margin-top: 10px;
        }

        .careerOppurtinitesListWrapper {
            padding-top: 10px;
        }
    }
}