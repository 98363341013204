.sdsRoot {
    padding-bottom: 10px;

    .sdsHero {
        background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/sds-header.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;

        .sub-heading1 {
            text-align: center;
            color: white;
            font-family: clarendon-text-pro, serif;
            font-weight: 400;
            font-style: normal;
            text-shadow: 2px 2px black;
        }
            
    }

    h3, h6 {
        font-weight: bold;

    }

    .sdsmanual {
        margin-top: 10px;
    }

    .subtitle2 {
        padding-bottom: 15px;
        color: #F4DD09;
    }
    
    .manualContent {
        background-color: #1d1d1d;
        color: white;
        padding: 25px 25px 0px 25px;
        height: 100%;

        a {
            text-decoration: none;
            color: #3f51b5;
        }

        .manualImgWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 25px;
            
            .manualImg {
                width: 100px;
            }
        }

    }
    .sdsContent {
        background-color: #1d1d1d;
        color: white;
        padding: 25px 25px 0px 25px;
        height: 100%;

        a {
            text-decoration: none;
            color: #3f51b5;
        }

        .sdsImgWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 25px;
            
            .sdsImg {
                width: 100px;
            }
        }

    }

    .mTable {
        margin-top: 35px;

        .MuiPaper-rounded {
            border-radius: 0px;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
            @media (hover: none) {
                border-bottom: 1px solid white;
            }
        }
        .MuiInput-underline:after {
            border-bottom: 2px solid #F4DD09;
        }

        .MuiTypography-h6, .MuiSelect-icon, .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon, .MuiButtonBase-root {
            color: #F4DD09;
        }
        .MuiPaper-elevation2 {
            background-color: #1d1d1d;
        }
        .MuiSelect-root, .MuiTypography-caption {
            color: white;
        }
        .MuiInput-underline:before {
            border-bottom: 1px solid white;
        }

        .MuiTableCell-footer {
            border-bottom: none;
        }

        .MuiTableCell-body {
            color: white;
        }
    }

}