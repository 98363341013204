@media screen and (max-width: 600px) {
    .cylinderRoot {
        .sameHeight {
            height: auto !important;
        }
    }
}

.cylinderRoot {
    .cylinderHero {
        background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/acm-header.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h5 {
        font-weight: bold;
    }

    .subtitle2 {
        text-align: center;
        color: white;
        font-family: clarendon-text-pro, serif;
        font-weight: 400;
        font-style: normal;
        text-shadow: 2px 2px black;
    }

    .justifying {
        text-align: justify;
    }

    .cylinderManagementContainer {
        .cylinderContent {
            margin: 10px 0;
        }

        .cylinderPaper {
            background-color: #1d1d1d;
            color: white;
            padding: 25px;
        }
        
        .cylinderSubtitle {
            color: #F4DD09;
        }

        .subtitle3 {
            color: #F4DD09;
            margin-bottom: 10px;
        }

        .white {
            color: white;
            text-align: justify;
        }
        .sameHeight {
            height: 175px;
        }
        .nomargin {
            margin: 0;
        }
    }
}