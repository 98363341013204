.signup-root {
    .captcha {
        padding-top: 15px;
    }

    .newsletter {
        background-color: #F4DD09;
        text-align: center;
        margin-bottom: 10px;
        padding: 25px 0px;

        h4 {
            font-weight: bold;
        }

        .form-wrapper {
            margin: 16px 15% 0 15%;
        }

        .email-submit {
            height: 56px;
            margin-top: 16px;
            color: #1d1d1d !important; 
        }

        .captcha {
            align-items: center;
            justify-content: center;
            display: flex;    
        }

        .popup-textfield {
            margin-top: 5px;
            color: white;
        }

        .PrivateNotchedOutline-root-327, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiFormLabel-root.Mui-focused, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiFormLabel-root, .MuiButton-outlined {
            border-color: rgb(0, 0, 0);
        }
    
        .MuiFormLabel-root {
            color: rgb(0, 0, 0);
        }

        .Mui-error, .Mui-error .MuiOutlinedInput-notchedOutline, .Mui-error .MuiOutlinedInput-notchedOutline:focus, .Mui-error .MuiOutlinedInput-notchedOutline:hover {
            color: #d32f2f !important;
            border-color: #d32f2f !important;
        }
    }
}