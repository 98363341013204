@media screen and (max-width: 600px) {
    .TopButton {
        bottom: 25px !important;
        right: 25px !important;
    }

}

.TopButton {
    position: fixed;
    bottom: 75px;
    right: 75px;
    z-index: 1000;

    .ArrowIconWrapper {
        background-color: #F4DD09;
    }
}

.TopButton:hover {
    .ArrowIconWrapper {
        background-color: #F4DD09;

    }
}