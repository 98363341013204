

.AuthorizeRoot {
    margin-top: 10px;

    .slick-slide {    
        img { 
            margin-left: auto; 
            margin-right: auto; 
            height: 100px;
            width: 150px;
        }
    }
    
    .slick-next {
        right: 0px !important;
    }
    
    .slick-prev {
        left: 0px !important;
        z-index: 10;
    }
    
    .centerTitle {
        text-align: center;
        padding: 25px 0px;
    }
    
    .buttonIcon {
        color: white;
    }
    
    .slick-next, .slick-prev {
        color: white !important;
        &:before {
            content: ""!important;
        }
    }
    
    .slick-initialized .slick-track {
        display: flex;
        align-items: center;
    }
}
