.slick-slide {    
    img { 
        margin-left: auto; 
        margin-right: auto; 
        height: 100px;
        width: 150px;
    }
}

.slick-next {
    right: 0px !important;
}

.slick-prev {
    left: 0px !important;
    z-index: 10;
}

.centerTitle {
    text-align: center;
    padding: 25px 0px;
    font-weight: bold !important;
}

.buttonIcon {
    color: black;
}

.slick-next, .slick-prev {
    color: black !important;
    &:before {
        content: ""!important;
    }
}

.slick-initialized .slick-track {
    display: flex;
    align-items: center;
}

.MFGRoot {
    margin-top: 10px;
}
