.wisRoot {
    .wisHero {
        background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/welding-industrial-supplies-header.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .subtitle2 {
        text-align: center;
        color: white;
        font-family: clarendon-text-pro, serif;
        font-weight: 400;
        font-style: normal;
        text-shadow: 2px 2px black;
    }

    .containerWis {
        padding: 50px 0;

        .heading {
            font-weight: bold;
            padding-bottom: 25px;
        }
    }

    .wisDesc {
        padding: 50px 0;
        .wisContent {
            text-align: justify;
        }
    }

    
}