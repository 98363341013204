.employeeRoot {
  .employeeHero {
    background-image: linear-gradient(
        90deg,
        rgba(29, 29, 29, 0.9023984593837535) 0%,
        rgba(244, 221, 9, 0.15449929971988796) 50%,
        rgba(29, 29, 29, 0.896796218487395) 100%
      ),
      url('../../imgs/employee-directory-header.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    .heroSub {
      text-align: center;
      color: white;
      font-family: clarendon-text-pro, serif;
      font-weight: 400;
      font-style: normal;
      text-shadow: 2px 2px black;
    }
  }

  .employeeListRoot {
    margin-top: 10px;
    margin-bottom: 10px;

    .employeeListHeaderPaper {
      padding: 15px;
      margin: 10px 0px;
      background-color: #1d1d1d;

      .employeeListHeader {
        font-weight: bold;
        margin-bottom: 16px;
        color: #f4dd09;
        text-align: center;
      }

      .employeeSearch {
        width: 100%;
      }

      .MuiFormLabel-root {
        color: rgba(255, 255, 255, 0.35);
      }

      .MuiFormLabel-root.Mui-focused {
        color: #f4dd09;
      }

      .MuiInputBase-input {
        color: white;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #848484 !important;
      }
    }

    .employeeButtonGroup {
      padding-top: 10px;
      display: flex;
      justify-content: center;

      .employeeButton {
        background-color: #1d1d1d;
        color: #f4dd09;
        border-color: rgba(255, 255, 255, 0.35);
      }
      .employeeActiveButton {
        background-color: #f4dd09;
        color: #1d1d1d;
      }
    }

    .elPaper {
      border-left: 5px solid #f4dd09;
      background-color: #1d1d1d;
      color: white;
      margin-top: 80px;
      padding: 10px;

      .elImg {
        margin-left: 15px;
        margin-top: -90px;
        position: absolute;
        width: 150px;
        height: 150px;
        border: 2px solid white;
      }

      .elName {
        padding-top: 75px;
      }

      .elSub {
        display: flex;
        align-items: center;
        justify-content: left;
        padding-top: 10px;
      }

      .elIcon {
        padding-right: 10px;
        color: #f4dd09;
      }
    }
  }
}
