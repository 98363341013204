.aboutRoot {
    margin-bottom: 10px;
    .aboutHead {
        background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/about-us-header.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .subtitle2 {
            text-align: center;
            color: white;
            font-family: clarendon-text-pro, serif;
            font-weight: 400;
            font-style: normal;
            text-shadow: 2px 2px black;
        }   
    }
    
    .aboutContent {
        margin-top: 50px;

        .aboutDesc {
            padding-top: 10px;
        }

        h4 {
            font-weight: bold;
        }

        .superscript {
            vertical-align: super;
        }
        
        .centerOG {
            @media screen and (max-width: 960px) {
                margin-bottom: 50px;        
            }

            display: flex;
            justify-content: center;
            align-items: center;

            .ogbwsPic {
                @media screen and (max-width: 600px) {
                    width: 75%;
                }
            }
        }
    
        .historyHead {
            text-align: center;
            margin-top: 50px;
        }
    }
}
