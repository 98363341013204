.ecommerceButton {
    background-color: #F4DD09 !important;
    color: #1d1d1d !important;
}

.list-link {
    border-radius: 0 !important;
    text-transform: none !important;
}

.navroot {
    flex-grow: 1;
}

.test1:last-child .MuiDivider-root {
    display: none;
}

.MuiDrawer-paper {
    background-color: #1d1d1d !important;
    color: #F4DD09 !important;    
}

.MuiAppBar-colorPrimary {
    background-color: #1d1d1d !important;
    color: #F4DD09 !important;
}

.MuiDivider-root {
    background-color: #848484 !important;
}

.container {
    padding: 0 !important;
}

.list {
    width: 250px;
    .MuiList-padding {
        padding: 0;
    }
}

.title {
    flex-grow: 1;
}

.MuiMenu-paper {
    background-color:#1d1d1d !important;
    color: #F4DD09 !important;
}

.navpopout {
    .MuiPaper-rounded {
        border-radius: 0px 0px 4px 4px;
    }

    .MuiList-padding {
        padding: 0px;
    }
    div:last-of-type {
        .MuiDivider-root {
            display: none;
        }
    }
}