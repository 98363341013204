.financingRoot {
  
  .financingHead {
    background-image: linear-gradient(90deg, rgba(29,29,29,0.9023984593837535) 0%, rgba(244,221,9,0.15449929971988796) 50%, rgba(29,29,29,0.896796218487395) 100%), url('../../imgs/BWS Financing Header.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  .subtitle2 {
    text-align: center;
    color: white;
    font-family: clarendon-text-pro, serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px black;
  }
  
  .financingContent {
    text-align: center;
    margin: 25px;
    padding-bottom: 40px;
    color: #1d1d1d;

    .financingWrapper {
      height: 100%;
      padding: 15px;
      color: #1d1d1d;

      // .financingTitle {
      //   color: #F4DD09;
      // }

      .financingImgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 25px;

        .financingImg {
          height: 100px;
        }
      }

      .financingButton {
        margin-top: 25px;
        background-color: #F4DD09 !important;
        color: #1d1d1d !important;
      }

      ul {
        text-align: left;
      }
    }
  }
}