.popup-root {
    .MuiPaper-root {
        background-color: #1d1d1d;
        color: #F4DD09;
        
        .popup-title {
            font-size: 2rem;
        }

        
        .popup-text {
            color: white;
        }
        
        .popup-note {
            font-size: 0.875rem;
            color: #b1b3b5;
            padding-bottom: 10px;
            font-style: italic;
        }

        .popup-button {
            color: #F4DD09;
        }
        
        .popup-textfield {
            margin-top: 5px;
            color: white;
        }
        
        .contact-captcha {
            margin-top: 20px;
            justify-content: center;
            display: flex;
        }
        
        .MuiInputBase-root {
            color: white;
        }
        
        .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline:focus, .MuiOutlinedInput-notchedOutline:hover {
            border-color: #F4DD09 !important;
        }

        .MuiFormLabel-root {
            color: #F4DD09 !important;
        }

        .Mui-error, .Mui-error .MuiOutlinedInput-notchedOutline, .Mui-error .MuiOutlinedInput-notchedOutline:focus, .Mui-error .MuiOutlinedInput-notchedOutline:hover {
            color: #d32f2f !important;
            border-color: #d32f2f !important;
        }
    }
}